// ページ系
export const loginPath = "/";
export const homePath = "/home";
export const dashboardPath = "/dashboard";
export const analysisPath = "/analysis";
export const influencerTicketsPath = "/influencer-tickets";
export const reportPath = "/report?version=test";
export const storeAccountInsightPath = "/store-account-insight?version=test";

export const influencerTicketsVisitPath = "/influencer-tickets/visit";
export const influencerTicketsOfferPath = "/influencer-tickets/visit/offering";
export const influencerTicketsRequestingPath = "/influencer-tickets/visit/requesting";
export const influencerTicketsActivePath = "/influencer-tickets/visit/active";
export const influencerTicketsJudgeSchedulingPath = "/influencer-tickets/visit/scheduling";
export const influencerTicketsScheduledPath = "/influencer-tickets/visit/scheduled";
export const influencerTicketsUsedPath = "/influencer-tickets/visit/used";
export const influencerTicketsCheckedPath = "/influencer-tickets/visit/checked";
export const influencerTicketsRatedPath = "/influencer-tickets/visit/rated";
export const influencerTicketsDonePath = "/influencer-tickets/visit/done";

export const influencerTicketsOrderPath = "/influencer-tickets/order";
export const influencerTicketsOrderOfferPath = "/influencer-tickets/order/offering";
export const influencerTicketsOrderRequestingPath = "/influencer-tickets/order/requesting";
export const influencerTicketsOrderActivePath = "/influencer-tickets/order/active";
export const influencerTicketsOrderUsedPath = "/influencer-tickets/order/used";
export const influencerTicketsOrderCheckedPath = "/influencer-tickets/order/checked";
export const influencerTicketsOrderRatedPath = "/influencer-tickets/order/rated";

export const influencerTicketsPathById = (id: string) => `/influencer-tickets/${id}`;
export const influencerTicketChatRoomPathById = (id: string) => `/influencer-tickets/${id}/chat-room`;
export const brandsPath = "/brands";
export const storesPath = "/stores";
export const storesPathById = (id: string) => `/stores/${id}`;
export const staffsPath = "/staffs";
export const staffsPathById = (id: string) => `/staffs/${id}`;
export const ticketsPath = "/tickets";
export const ticketsNewPath = "/tickets/new";
export const ticketsPathById = (id: string) => `/tickets/${id}`;
export const ticketsEditPathById = (id: string) => `/tickets/${id}/edit`;
export const directOfferPath = "/direct-offer";
export const directOfferFavoritePath = "/direct-offer/favorite";

export const topInfluencerPath = `${directOfferPath}/top-influencer`;
export const inboundInfluencerPath = `${directOfferPath}/inbound-influencer?version=test`;

export const influencersPath = "/influencers";
export const influencerPathById = (id: string) => `/influencers/${id}`;

// previewPath
export const ticketsPreviewPath = (query: string) => {
  if (process.env.APP_ENV === "production") {
    return `https://app.unit-g.com/tickets/preview?id=${query}`;
  } else {
    return `https://app-stg.unit-g.com/tickets/preview?id=${query}`;
  }
};
export const shopPreviewPath = (query: string) => {
  if (process.env.APP_ENV === "production") {
    return `https://app.unit-g.com/shops/preview?id=${query}`;
  } else {
    return `https://app-stg.unit-g.com/shops/preview?id=${query}`;
  }
};

// マイページ、設定系
export const mypagePath = `/mypage`;
export const mypageAccountInfoPath = `/mypage/account-info`;
export const mypageInstagramLink = `/mypage/instagram-link`;
export const mypageAverageCustomerSpend = `/mypage/average-customer-spend`;
export const mypageResetMailPath = `/mypage/reset-mailaddress`;
export const mypageResetPassPath = `/mypage/reset-password`;

// サポート・利用規約など、その他
export const termsPath = "https://drive.google.com/file/d/1XTcUkky_bQXzoVjMpaVkQzxte2zgPUyK/view";
export const privacyPolicyPath = "https://unit-g.com/privacy_policy/";
export const serviceOrganizationPath = "https://unit-g.com/corporation/";
export const storeSupportPath = "https://service.unit-g.com/contact/";
export const alertInfluencerSupportPath =
  "https://docs.google.com/forms/d/e/1FAIpQLScnBJ2GeGPhAvF5xZRu7AdgkNQ4KC6xjbbANI3a1ND_SQZNBg/viewform?usp=sf_link";
export const helpPath = "https://forstore.zendesk.com/hc/ja";

// InstagramビジネスアカウントとFacebookページを連携する。
export const INSTAGRAM_BUSINESS_SETTING_PATH =
  "https://www.facebook.com/help/instagram/502981923235522/?helpref=uf_share";
export const FACEBOOK_PAGE_SETTING_PATH = "https://www.facebook.com/pages/creation/?ref_type=facebook_business_website";
export const LINKED_INSTAGRAM_FACEBOOK_PATH = "https://ja-jp.facebook.com/business/help/898752960195806";
